define("ember-power-select/components/power-select/trigger", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @select.selected}}
    {{#if @selectedItemComponent}}
      {{component (ensure-safe-component @selectedItemComponent) extra=(readonly @extra) option=(readonly @select.selected) select=(readonly @select)}}
    {{else}}
      <span class="ember-power-select-selected-item">{{yield @select.selected @select}}</span>
    {{/if}}
    {{#if (and @allowClear (not @select.disabled))}}
      <span class="ember-power-select-clear-btn" role="button" {{on "mousedown" this.clear}} {{on "touchstart" this.clear}}>&times;</span>
    {{/if}}
  {{else}}
    {{component (ensure-safe-component @placeholderComponent) placeholder=@placeholder}}
  {{/if}}
  <span class="ember-power-select-status-icon"></span>
  
  */
  {
    "id": "RdjJMxAX",
    "block": "[[[41,[30,1,[\"selected\"]],[[[41,[30,2],[[[1,\"    \"],[46,[28,[37,2],[[30,2]],null],null,[[\"extra\",\"option\",\"select\"],[[28,[37,3],[[30,3]],null],[28,[37,3],[[30,1,[\"selected\"]]],null],[28,[37,3],[[30,1]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-selected-item\"],[12],[18,7,[[30,1,[\"selected\"]],[30,1]]],[13],[1,\"\\n\"]],[]]],[41,[28,[37,5],[[30,4],[28,[37,6],[[30,1,[\"disabled\"]]],null]],null],[[[1,\"    \"],[11,1],[24,0,\"ember-power-select-clear-btn\"],[24,\"role\",\"button\"],[4,[38,7],[\"mousedown\",[30,0,[\"clear\"]]],null],[4,[38,7],[\"touchstart\",[30,0,[\"clear\"]]],null],[12],[1,\"×\"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[46,[28,[37,2],[[30,5]],null],null,[[\"placeholder\"],[[30,6]]],null],[1,\"\\n\"]],[]]],[10,1],[14,0,\"ember-power-select-status-icon\"],[12],[13],[1,\"\\n\"]],[\"@select\",\"@selectedItemComponent\",\"@extra\",\"@allowClear\",\"@placeholderComponent\",\"@placeholder\",\"&default\"],false,[\"if\",\"component\",\"ensure-safe-component\",\"readonly\",\"yield\",\"and\",\"not\",\"on\"]]",
    "moduleName": "ember-power-select/components/power-select/trigger.hbs",
    "isStrictMode": false
  });
  let Trigger = (_class = class Trigger extends _component2.default {
    clear(e) {
      e.stopPropagation();
      this.args.select.actions.select(null);
      if (e.type === 'touchstart') {
        return false;
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class);
  _exports.default = Trigger;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Trigger);
});